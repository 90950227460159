@import "../css/variables";

.appHeader {
  position: relative;
  height: 470px;

  .mask {
    height: 470px;
    width: 100%;
  }
}


.headTitle {
  margin-top: 120px;
  color: white;
  margin-left: 70px;

  .titleOne {

    font-size: 30px;
    font-weight: 400;
  }

  .titleTwo {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
  }
}


.content {
  position: relative;
  top: -100px;
  width: $content;
  margin-left: auto;
  margin-right: auto;
  background: #EFEFEF;

}


.tabOne {
  height: 460px;
  background: white;
  background-image: url('./img/tab_one_back.png');
  text-align: center;

  .tabOneDesc {
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    color: #9B9B9B;
    width: 600px;
    line-height: 24px;
    margin-top: 30px;
  }
}

.title {
  line-height: 120px;
  font-size: 24px;
  font-weight: 400;
  color: #0D253E;
  text-align: center;
}
.tabTwo {
  background: white;
  margin-top: 22px;
  height: 703px;
  .tabTwoDesc {
    font-size: 36px;
    text-align: center;
    font-weight: 100;
    color: #2D2D2D;
    line-height: 50px;
  }
  .tabTwoImg {
    margin-top:40px;
    width: 1000px;
    height: 420px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;

  }
}

.tabThree {
  background: white;
  margin-top: 22px;
  height: 450px;
  .tabThreeContent {
    display: flex;
    width: 1000px;
    margin-bottom:30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;

  }
  .tabThreeItem {
    width: 500px;
    .tabThreeItemImg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      vertical-align: top;
    }
    .tabThreeItemText {
      font-weight: 400;
      font-size: 15px;
      margin-left: 10px;
      display: inline-block;
      vertical-align: top;
    }
  }
}
