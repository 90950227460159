.headWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  a {
    text-decoration: none;
    font-size: 18px;
    margin-left: 80px;
    color: white;
  }
}
