@import "../css/variables";

.headTitle {
  margin-top: 120px;
  color: white;
  margin-left:70px;
  .titleOne {

    font-size: 30px;
    font-weight: 400;
  }
  .titleTwo {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
  }
}

.appHeader {
  position: relative;
  height: 470px;
  .mask {
    height: 470px;
    width: 100%;
  }
}


.content {
  position: relative;
  top: -100px;
  width: $content;
  margin-left: auto;
  margin-right: auto;
  background: #EFEFEF;
}

.tabOne {
  background: white;
  text-align: center;
  padding-bottom: 100px;
  padding-top:50px;
  .title {
    font-size: 24px;
    line-height:33px;
  }
  .tabOneContent {
    margin-top: 40px;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    .tabOneItem {
      border-top: 4px solid #D0021B;
      background: #F8F8F8;
      width: 500px;
      height: 204px;

      .tabOneItemTitle {
        padding-top:29px;
        font-weight: 400;
        font-size:20px;
        line-height: 28px;
      }
      .tabOneItemDesc {
        padding-left:29px;
        padding-right:29px;

        font-size:13px;
        color: #9B9B9B;
        line-height: 24px;
      }
    }
  }
}


.tabTwo {
  margin-top:20px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  .tabTwoContent {
    width: 570px;
    height: 370px;
    background: white;
    .tabTwoContentTitle {
      font-size:24px;
      font-weight: 400;
      margin-top:50px;
      margin-bottom: 50px;
    }
  }
  .tabTwoItem {

  }
}
.tabThree {
  background: white;
  margin-top: 20px;
  padding-top: 65px;
  padding-bottom: 65px;

  .tabThreeTitle {
    font-size:24px;
    font-weight: 400;
    text-align: center;
  }
  .tabThreeItem {
    width:630px;
    margin-bottom: 17px;
    .tabThreeItemImg {
      width:100px;
      height: 100px;
      vertical-align: top;
    }
    .tabThreeItemTxt {
      width: 390px;
      display: inline-block;
      vertical-align: top;
      margin-top: 6px;
      margin-left:20px;
      margin-right:20px;
    }
    .tabThreeItemTitle {
      font-size:18px;
      color: #4a4a4a;

    }
    .tabThreeItemDesc {
      font-size:13px;
      margin-top:8px;
      clear:both;
      color:#9B9B9B;
      width: 300px;
      line-height: 24px;
    }
  }
}

.question {
  width:900px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 34px;
  padding-top:34px;
  .ask {
    font-size: 14px;
    line-height: 20px;

  }
  .answer {
    margin-top:10px;
    font-size:13px;
    color: #9B9B9B;
    line-height: 24px;
  }
}
