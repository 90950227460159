



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




.headTitle {
  margin-top: 70px;
  color: white;
  text-align: center;
  .titleOne {
    font-size: 42px;
    letter-spacing: 8px;
    font-weight: 600;
  }
  .titleTwo {
    font-size: 30px;
    margin-top: 20px;
    letter-spacing: 38px;
  }
}

.headBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgba(0, 0, 0, 0.4);
  .bottomItem {
    display: inline-block;

    float:left;
    color: white;
    .img {
      height: 44px;
      width: 44px;
    }
    .title {
      font-size: 20px;
      line-height: 26px;
    }
    .desc {
      font-size:13px;
      line-height: 17px;
      width: 170px;
    }
  }
}

.contentOne {
  width: 1160px;
  margin-left: auto;
  margin-right: auto;
  height: 640px;

  .contentOneTitle {
    margin-top:60px;
    text-align: center;
    font-size: 28px;
    letter-spacing: 3px;

  }
  .contentOneDesc {
    margin-top:30px;
    text-align: center;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 19px;
    letter-spacing: 3px;
  }
}
.contentOneItem {
  background: white;
  height: 315px;
  width: 260px;
  text-align: center;
  .contentOneImg {
    height: 100px;
    width: 100px;
    margin-top:40px;
    border:1px solid lightgray;
  }
  .contentOneItemTitle {
    margin-top:10px;
    color: #4A4A4A;
    font-size: 18px;
  }
  .contentOneItemDesc {
    margin-top:22px;
    padding-left:20px;
    padding-right: 20px;
    font-size: 13px;
    color: #9B9B9B;
    line-height: 24px;
  }
}

.contentTwo {
  width: 1160px;
  margin-left: auto;
  margin-right: auto;
  height: 640px;
  background: white;
  .contentTwoImg {
    height: 320px;
    width: 100%;
    align-items: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  .contentTwoItem {
    width:220px;
    position:relative;
    text-align: center;

    top: -50px;
    .contentTwoItemImgBorder {
      padding:20px;
      background: white;
      display: inline-block;
      border-radius: 50px;
      border:1px solid #EEEEEE;
    }
    .contentTwoItemImg {
      height: 60px;
      width: 60px;
      border: 1px solid lightgray;
    }
    .contentTwoItemTitle {
      margin-top:38px;
      font-size:18px;
      color: #4A4A4A;
      line-height: 24px;
    }
    .contentTwoItemDesc {
      margin-top: 19px;
      font-size: 13px;
      line-height: 24px;
      color: #9B9B9B;

    }
  }
}


