@import "../css/variables";

.appHeader {
  position: relative;
  height: 470px;

  .mask {
    height: 470px;
    width: 100%;
  }
}


.headTitle {
  margin-top: 120px;
  color: white;
  margin-left: 70px;

  .titleOne {

    font-size: 30px;
    font-weight: 400;
  }

  .titleTwo {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
  }
}

.content {
  position: relative;
  top: -100px;
  width: $content;
  margin-left: auto;
  margin-right: auto;
  background: #EFEFEF;

}

.tabOne {
  height: 460px;
  background: white;
  background-image: url('./img/tab_one_back.png');
  text-align: center;

  .tabOneDesc {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    color: #4a4a4a;
    width: 500px;
    line-height: 32px;
    margin-top: 100px;
  }
}

.title {
  line-height: 120px;
  font-size: 24px;
  font-weight: 400;
  color: #0D253E;
  text-align: center;
}

.tabContent {
  display: flex;
  justify-content: space-around;
}

.tabTwo {
  height: 505px;
  background: white;
  margin-top: 22px;

  .tabTwoItem {
    width: 224px;
    text-align: center;

    .tabTwoItemImg {
      width: 150px;
      height: 150px;
    }

    .tabTwoItemTitle {
      font-size: 18px;
      margin-top: 40px;
      color: #4a4a4a;
    }

    .tabTwoItemDesc {
      margin-top: 28px;
      color: #9B9B9B;
      font-size: 13px;
      line-height: 24px;
    }
  }
}

.tabThree {
  height: 420px;
  background: white;
  margin-top: 22px;
  .tabThreeItem {
    width: 360px;
    .tabThreeItemImg {
      width:360px;
      height: 128px;
    }
    .tabThreeItemDesc {
      margin-top: 20px;
      color: #9B9B9B;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
    }
  }
}
.tabFour {
  height: 540px;
  background: white;
  margin-top:22px;
  .tabFourItem {
    width: 300px;
    border: 1px solid #DADADA;
    height: 328px;
    text-align: center;
    .tabFourItemImg {
      height: 164px;
      width: 300px;
    }
    .tabFourItemTitle {
      font-weight: 400;
      color: #3983D0;
      font-size:18px;
      margin-top: 56px;
    }
    .tabFourItemDesc {
      margin-top:8px;
      font-size:13px;
      color: #9B9B9B;
      line-height: 24px;
    }
  }
}
.tabFive {
  background: white;
  margin-top: 22px;
  height: 525px;
  position: relative;
  .tabFiveBackImg {
    height: 381px;
    width: 1006px;
    position: absolute;
    top:71px;
    left:77px;
  }
  .tabFiveContent {
    position: absolute;
    right: 153px;
    top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
  }
  .tabFiveItem {
    background: white;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1), 0px 0px 7px 0px rgba(0, 0, 0, 0.28);
    border-radius: 2px;
    width:440px;
    height: 128px;
    .tabFiveItemTitle {
      font-weight: 400;
      color: #3983D0;
      font-size:18px;
      margin-top:29px;
      width: 360px;
      margin-left: auto;
      margin-right: auto;
    }
    .tabFiveItemDesc {
      width: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top:8px;
      font-size:13px;
      color: #9B9B9B;
      line-height: 24px;
    }
  }
}

.tabSix {
  margin-top:22px;
  background: white;
  height: 436px;
  .tabSixItem {
    text-align: center;
    width: 160px;
    margin-top: 20px;
    .tabSixItemImg {
      width: 100px;
      height: 100px;
    }
    .tabSixItemDesc {
      margin-top: 46px;
      font-size: 13px;
      color: #9B9B9B;
      line-height: 24px;
    }
  }
}

.tabSeven {
  margin-top:22px;
  background: white;
  height: 520px;
  .tabSevenItem {
    width: 500px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 58px;
    .tabSevenItemImg {
      width: 60px;
      height: 60px;
      vertical-align: top;
    }
    .tabSevenItemTxt {
      margin-left:20px;
      display: inline-block;
    }
    .tabSevenItemTitle {
      font-size:18px;
      color: #4a4a4a;
    }
    .tabSevenItemDesc {
      font-size:13px;
      margin-top:10px;
      color:#9B9B9B;
    }
  }

}
