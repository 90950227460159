.bottom {
  width: 100%;
  height: 300px;
  background: #2A363C;
  color: #9B9B9B;
  a {
    color: #9B9B9B;
    text-decoration: none;
    margin-left:16px;
    margin-right:16px;
    font-size:13px;
  }
  .bottomWrap {
    width: 1160px;
    margin-left: auto;
    margin-right: auto;
    .bottomOne {
      display: flex;
      justify-content: space-around;
      height: 90px;
      align-items: center;
      border-bottom: 1px solid #9B9B9B;
    }
    .bottomOneItem {
      font-size: 16px;
      line-height: 20px;
      vertical-align: center;
      .bottomOneItemImg {
        width: 19px;
        height: 19px;
        vertical-align: middle;
      }
    }
    .bottomTwo {
      .link {
        height: 100px;
        display: flex;
        align-items: center;

      }
    }
  }
}
